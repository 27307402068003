<template>
  <validation-observer
    #default="{ valid }"
    ref="refFormObserver"
    class="w-100"
  >
    <div
      v-if="isFileSuccess || isImporting"
      class="box d-flex bg-light flex-column justify-content-center align-items-center py-2 px-3"
    >
      <!-- ANCHOR Top table -->
      <div class="d-flex justify-content-between align-items-center w-100 mb-75">
        <h4>
          {{ $t('importValidateHeader_1') }} <em class="text-muted">{{ fileName }}</em> {{ $t('importValidateHeader_2') }}
        </h4>
        <div>
          <b-button
            variant="outline-warning"
            @click="$emit('reset')"
          >
            {{ $t('uploadAgain') }}
          </b-button>
        </div>
      </div>
      <b-overlay
        :show="isImporting"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
      >
        <!-- SECTION Table data import -->
        <b-table
          sticky-header
          responsive
          show-empty
          :empty-text="$t('noRecordFund')"
          :items="tableData"
          :fields="tableFields"
          no-border-collapse
        >

          <!-- Render column name -->
          <template
            v-for="column in tableFields"
            v-slot:[`head(${column.key})`]="data"
          >
            <span :key="column.label">
              {{ $t(`customer.${data.label}`) }}
            </span>
          </template>

          <!-- ANCHOR STT -->
          <template #cell(STT)="data">
            {{ data.index + 1 }}
          </template>

          <!-- ANCHOR Customer First Name -->
          <template
            #cell(Customer_First_Name)="data"
          >
            <validation-provider
              #default="validationContext"
              immediate
              name="Customer First Name"
              rules="required|max:30"
              mode="eager"
              :vid="`firstName${data.index}`"
            >
              <b-form-input
                :id="`firstName${data.index}`"
                v-model="data.item['Customer First Name']"
                lazy-formatter
                :formatter="trimInput"
                :state="getValidationState(validationContext) === false ? false : null"
              />
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`firstName${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR Customer Last Name -->
          <template
            #cell(Customer_Last_Name)="data"
          >
            <validation-provider
              #default="validationContext"
              immediate
              name="Customer Last Name"
              rules="required|max:30"
              mode="eager"
              :vid="`lastName${data.index}`"
            >
              <b-form-input
                :id="`lastName${data.index}`"
                v-model="data.item['Customer Last Name']"
                lazy-formatter
                :formatter="trimInput"
                :state="getValidationState(validationContext) === false ? false : null"
              />
              <!-- <span v-else>{{ data.item['Employee First Name'] }}</span> -->
              <!-- :class="getValidationState(validationContext) ? bg-danger : null" -->

              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`lastName${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
                :title="validationContext.errors[0]"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR gender -->
          <template
            #cell(Gender)="data"
          >
            <validation-provider
              #default="validationContext"
              immediate
              name="Gender"
              :rules="`required|isBelongTo:${genderOptionsToString()}`"
              mode="eager"
              :vid="`gender${data.index}`"
            >
              <b-form-input
                :id="`gender${data.index}`"
                v-model="data.item['Gender']"
                lazy-formatter
                :formatter="trimInput"
                :state="getValidationState(validationContext) === false ? false : null"
              />
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`gender${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR Phone Number -->
          <template
            #cell(Phone_Number)="data"
          >
            <validation-provider
              #default="validationContext"
              immediate
              name="Phone Number"
              :rules="`required|phoneNumber|isUnique:customer,phonenumber|duplicateImport:${phoneList.toString()}`"
              mode="eager"
              :vid="`phoneNumber${data.index}`"
            >
              <b-form-input
                :id="`phoneNumber${data.index}`"
                v-model="data.item['Phone Number']"
                v-remove-non-numeric-chars.allNumber
                :state="getValidationState(validationContext) === false ? false : null"
                :formatter="trimInput"
                :debounce="300"
              />
              <!-- maxlength="10" -->
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`phoneNumber${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR email address -->
          <template
            #cell(Email_Address)="data"
          >
            <validation-provider
              #default="validationContext"
              immediate
              name="Email"
              :rules="`required|email|max:50|isUnique:customer,email|duplicateImport:${emailList.toString()}`"
              mode="eager"
              :vid="`emailAddress${data.index}`"
            >
              <b-form-input
                :id="`emailAddress${data.index}`"
                v-model="data.item['Email Address']"
                :formatter="trimInput"
                :debounce="300"
                :state="getValidationState(validationContext) === false ? false : null"
              />
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`emailAddress${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR Birthday -->
          <template
            #cell(Birthday)="data"
          >
            <validation-provider
              #default="validationContext"
              immediate
              name="Birthday"
              rules=""
              :vid="`birthday${data.index}`"
            >
              <b-form-datepicker
                :id="`birthday${data.index}`"
                v-model="data.item['Birthday']"
                :state="getValidationState(validationContext) === false ? false : null"
                :date-format-options="{day: '2-digit', month: '2-digit', year: 'numeric' }"
                :max="new Date()"
                :min="new Date(1920, 0, 1)"
                show-decade-nav
                hide-header
                locale="vi"
                no-flip
                boundary="window"
                placeholder=""
              />
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`birthday${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR Description -->
          <template
            #cell(Description)="data"
          >
            <validation-provider
              #default="validationContext"
              immediate
              name="Description"
              rules=""
              mode="eager"
              :vid="`description${data.index}`"
            >
              <b-form-input
                :id="`description${data.index}`"
                v-model="data.item['Description']"
                lazy-formatter
                :formatter="trimInput"
                :state="getValidationState(validationContext) === false ? false : null"
              />
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`description${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR Country -->
          <!-- <template
            #cell(Country)="data"
          >
            <validation-provider
              #default="validationContext"
              immediate
              name="Country"
              rules=""
              mode="eager"
              :vid="`country${data.index}`"
            >
              <b-form-input
                :id="`country${data.index}`"
                v-model="data.item['Country']"

                :state="getValidationState(validationContext) === false ? false : null"
              />
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`country${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template> -->

          <!-- ANCHOR City -->
          <!-- <template
            #cell(City)="data"
          >
            <validation-provider
              #default="validationContext"
              immediate
              name="City"
              rules=""
              mode="eager"
              :vid="`city${data.index}`"
            >
              <b-form-input
                :id="`city${data.index}`"
                v-model="data.item['City']"

                :state="getValidationState(validationContext) === false ? false : null"
              />
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`city${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template> -->

          <!-- ANCHOR District -->
          <!-- <template
            #cell(District)="data"
          >
            <validation-provider
              #default="validationContext"
              immediate
              name="District"
              rules=""
              mode="eager"
              :vid="`district${data.index}`"
            >
              <b-form-input
                :id="`district${data.index}`"
                v-model="data.item['District']"

                :state="getValidationState(validationContext) === false ? false : null"
              />
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`district${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template> -->

          <!-- ANCHOR Ward -->
          <!-- <template
            #cell(Ward)="data"
          >
            <validation-provider
              #default="validationContext"
              immediate
              name="Ward"
              rules=""
              mode="eager"
              :vid="`ward${data.index}`"
            >
              <b-form-input
                :id="`ward${data.index}`"
                v-model="data.item['Ward']"

                :state="getValidationState(validationContext) === false ? false : null"
              />
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`ward${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template> -->

          <!-- ANCHOR Street -->
          <template
            #cell(Street)="data"
          >
            <validation-provider
              #default="validationContext"
              immediate
              name="Street"
              rules=""
              mode="eager"
              :vid="`street${data.index}`"
            >
              <b-form-input
                :id="`street${data.index}`"
                v-model="data.item['Street']"
                lazy-formatter
                :formatter="trimInput"
                :state="getValidationState(validationContext) === false ? false : null"
              />
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`street${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR action -->
          <!-- <template
            #cell(action)="data"
          >
            <div class="d-flex justify-content-center align-item w-100">
              <feather-icon
                icon="EditIcon"
                size="20"
                style="cursor: pointer"
                stroke-width="2"
                name="edit"
                class="action-button-custom-class w-100 h-100 px-1 py-50"
                @click="toggleRowEditable(data.index)"
              />
              <feather-icon
                icon="TrashIcon"
                size="20"
                style="cursor: pointer"
                stroke-width="2"
                name="delete"
                class="action-button-custom-class w-100 h-100 px-1 py-50"
                @click="deleteRowHandle(data.index)"
              />
            </div>
          </template> -->
        </b-table>
        <!-- !SECTION -->
      </b-overlay>
    </div>

    <!-- ANCHOR BUTTON ACTION -->
    <div class="w-full m-50 d-flex justify-content-center">
      <b-button
        variant="secondary"
        class="mr-1"
        @click.prevent="$emit('hide-modal')"
      >
        {{ $t('cancel') }}
      </b-button>
      <b-button
        :variant="valid ? 'primary':'outline-secondary'"
        class="ml-1"
        :disabled="(!valid) || !canAccess('customer.importCustomer')"
        @click.prevent="$emit('import-file', tableData)"
      >
        {{ $t('import') }}
      </b-button>
    </div>
  </validation-observer>
</template>

<script>
import {
  BButton, BTable, BFormInput, BTooltip, BFormDatepicker, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { toRefs, computed } from '@vue/composition-api'

import { genderOptionsToString } from '@/constants/selectOptions'

import formValidation from '@core/comp-functions/forms/form-validation'
import { trimInput } from '@core/comp-functions/forms/formatter-input'

import {
  required, min, email, isUnique, duplicateImport, isBelongTo,
} from '@validations'

export default {
  components: {
    BButton,
    BFormDatepicker,
    BOverlay,
    BTable,
    BFormInput,
    BTooltip,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isFileSuccess: {
      type: Boolean,
      default: false,
    },
    isImporting: {
      type: Boolean,
      default: false,
    },
    fileName: {
      type: String,
      default: '',
    },
    dataImport: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const tableFields = [
      { label: 'STT', key: 'STT' },
      { label: 'Customer_Last_Name', key: 'Customer_Last_Name' },
      { label: 'Customer_First_Name', key: 'Customer_First_Name' },
      { label: 'Gender', key: 'Gender' },
      { label: 'Phone_Number', key: 'Phone_Number' },
      { label: 'Email_Address', key: 'Email_Address' },
      { label: 'Birthday', key: 'Birthday', thStyle: { width: '15%' } },
      { label: 'Description', key: 'Description' },
      { label: 'Street', key: 'Street' },
      // { label: 'Country', key: 'Country' },
      // { label: 'City', key: 'City' },
      // { label: 'District', key: 'District' },
      // { label: 'Ward', key: 'Ward' },
      // { label: 'Action', key: 'action' },
    ]

    const { refFormObserver, getValidationState, resetForm } = formValidation()

    const tableData = toRefs(props).dataImport

    // List for handle validate duplicate
    const emailList = computed(() => tableData.value.map(ele => ele['Email Address']))
    const phoneList = computed(() => tableData.value.map(ele => ele['Phone Number']))

    // const deleteRowHandle = index => {
    //   tableData.value.splice(index, 1)
    // }

    // const rowEditableFlag = reactive({ value: new Array(tableData.value.length) })

    // function toggleRowEditable(index) {
    //   rowEditableFlag.value[index] = !rowEditableFlag.value[index]
    //   console.log({ rowEditableFlag })
    // }

    return {
      // validateTableData,
      tableFields,
      tableData,

      // validation
      refFormObserver,
      getValidationState,
      resetForm,

      required,
      min,
      email,
      isUnique,
      isBelongTo,
      duplicateImport,

      genderOptionsToString,

      trimInput,
      emailList,
      phoneList,
    }
  },
}

</script>

<style lang="scss" scoped>
.box {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  padding: 30px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  border-radius: 4px;
  background: #161d31;
  height: 100%;
}

.action-button-custom-class{
  &:hover{
    background: rgba(22, 29, 49, 0.4);
    border-radius: 8px;
    color: white
  }
}

</style>

<style lang="scss">

// .table-danger{
//   background-color: #ea5455;
//   td{
//     color: #fff !important;
//   }
// }
</style>
